/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif;  }

@media (min-width: 575.98px) { 
  body {
    min-width: 1600px;
  }
}

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";

/* Fix ngb-modal-backdrop z-index */
ngb-modal-backdrop {
  z-index: 1050 !important;
}